import React from 'react';
import axios from 'axios'

class MainAppContainer extends React.Component {

  constructor() {
    super();
    this.state = {
      guestID: "",
      guestPhoneNumber: "",
      invitationTo: "",
      wtpsMsg: "",
      isAttending: "--",
      nbrOfAttending: 0,
      isVideoMuted: true,
      nbrOfInvited: "",
      checkYes: false,
      checkNo: false,
      didstart: false,
      apiCall: ""
    };
  }


  componentDidMount = async () => {

    let apiCall = ""
    await fetch("AppData.json").then(
      function (res) {
        return res.json()
      }).then(function (data) {
        apiCall = data.apiCall
      }).catch(
        function (err) {
          console.log(err, ' error')
        }
      )

    let tempData
    let guestID = window.location.search
    await axios({
      method: "get",
      url: `${apiCall + '/search' + guestID}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        tempData = response.data[0]
      })
      .catch(error => {
        if (axios.isCancel(error)) { console.log(error.message); }
      });

    this.setState({
      guestID: tempData.id ? tempData.id : '',
      invitationTo: tempData.name ? tempData.name : '',
      nbrOfInvited: tempData.NumberOfGuests ? tempData.NumberOfGuests : '',
      guestPhoneNumber: tempData.mobilenumber ? tempData.mobilenumber : '',
      wtpsMsg: tempData.wtpsMsg ? tempData.wtpsMsg : '',
      apiCall
    })
  }

  startCardTour = () => {
    document.getElementById("startPage").style.visibility = "hidden"
    document.getElementById("locationBottom").style.visibility = "visible"
    setTimeout(
      function () {
        document.getElementById("startPage").style.height = "calc(100% - 40px)"
        document.getElementById("rsvp").style.visibility = "visible"
        document.getElementById("rsvp").className = "fadeIn"
      },
      15000
    );
    this.setState({ didstart: true, isVideoMuted: false })
  }

  handleMute = () => {
    this.setState({ isVideoMuted: !this.state.isVideoMuted })
  }

  handleInput = event => {
    if (event.target.value && (event.target.value > parseInt(this.state.nbrOfInvited) || event.target.value < 0))
      this.setState({ nbrOfAttending: 0 })
    else
      this.setState({ nbrOfAttending: event.target.value })

    document.getElementById("demoInput").style.borderColor = "white"
  }

  increment = () => {
    if (parseInt(this.state.nbrOfAttending) + 1 > parseInt(this.state.nbrOfInvited))
      this.setState({ nbrOfAttending: this.state.nbrOfAttending })
    else
      this.setState({ nbrOfAttending: parseInt(this.state.nbrOfAttending) + 1 })

    document.getElementById("demoInput").style.borderColor = "white"
  }

  decrement = () => {
    if (parseInt(this.state.nbrOfAttending) - 1 < 0)
      this.setState({ nbrOfAttending: this.state.nbrOfAttending })
    else
      this.setState({ nbrOfAttending: parseInt(this.state.nbrOfAttending) - 1 })

    document.getElementById("demoInput").style.borderColor = "white"
  }

  handleSelect = data => event => {
    if (data === "checkYes")
      this.setState({ checkYes: true, checkNo: false, nbrOfAttending: this.state.nbrOfInvited })
    else
      this.setState({ checkYes: false, checkNo: true, nbrOfAttending: 0 })
    document.getElementById("radioAttending").style.color = "white"
    document.getElementById("demoInput").style.borderColor = "white"
  }

  reserve = async () => {

    if (this.state.checkYes === false && this.state.checkNo === false) {
      document.getElementById("radioAttending").style.color = "red"
      return
    }

    if (this.state.checkYes === true && (this.state.nbrOfAttending === 0 || this.state.nbrOfAttending === "")) {
      document.getElementById("demoInput").style.borderColor = "red"
      return
    }

    let params = {
      Attending: this.state.checkYes ? "Y" : "N",
      NumberOfAttendes: this.state.checkYes ? this.state.nbrOfAttending : "0"
    }

    await axios({
      method: "patch",
      url: `${this.state.apiCall + '/id/' + this.state.guestID}`,
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response)
          document.getElementById("rsvp").style.visibility = "hidden"
      })
      .catch(error => {
        if (axios.isCancel(error)) { console.log(error.message); }
      });

  }

  render() {
    let { isVideoMuted, nbrOfAttending, isAttending } = this.state
    return (
      <div id="viewsParentContainer">
        <div id="leafContainer" data-image="bigLeaf" className="bigLeaf">
          <div style={{ top: "115%", left: "4271px", animationName: "fade, drop", animationDuration: "21.5328s, 21.5328s", animationDelay: "0.649181s, 0.649181s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.2989s" }} />
          </div>
          <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
            <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
          </div>
          <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
          </div>
          <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
          </div>
          <div style={{ top: "115%", left: "2248px", animationName: "fade, drop", animationDuration: "26.1889s, 26.1889s", animationDelay: "0.078033s, 0.078033s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "11.4703s" }} />
          </div>
          <div style={{ top: "115%", left: "3547px", animationName: "fade, drop", animationDuration: "31.2799s, 31.2799s", animationDelay: "0.423443s, 0.423443s" }}>
            <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "12.0255s" }} />
          </div>
          <div style={{ top: "115%", left: "205px", animationName: "fade, drop", animationDuration: "30.8377s, 30.8377s", animationDelay: "0.623964s, 0.623964s" }}>
            <img alt="realLeaf" src="img/realLeaf3.png" style={{ animationName: "clockwiseSpin", animationDuration: "13.0019s" }} />
          </div>
          <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
            <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
          </div>
          <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
          </div>
          <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
          </div>
          <div style={{ top: "115%", left: "43px", animationName: "fade, drop", animationDuration: "35.0827s, 35.0827s", animationDelay: "0.36684s, 0.36684s" }}>
            <img alt="realLeaf" src="img/realLeaf3.png" style={{ animationName: "clockwiseSpin", animationDuration: "15.6451s" }} />
          </div>
          <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
            <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
          </div>
          <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
          </div>
          <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
          </div>
          <div style={{ top: "115%", left: "2826px", animationName: "fade, drop", animationDuration: "35.7176s, 35.7176s", animationDelay: "0.142368s, 0.142368s" }}>
            <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.8249s" }} />
          </div>
          <div style={{ top: "115%", left: "621px", animationName: "fade, drop", animationDuration: "37.7397s, 37.7397s", animationDelay: "0.396265s, 0.396265s" }}>
            <img alt="realLeaf" src="img/realLeaf3.png" style={{ animationName: "clockwiseSpin", animationDuration: "11.9869s" }} />
          </div>
          <div style={{ top: "115%", left: "295px", animationName: "fade, drop", animationDuration: "23.2892s, 23.2892s", animationDelay: "0.244458s, 0.244458s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "18.1451s" }} />
          </div>
          <div style={{ top: "115%", left: "3295px", animationName: "fade, drop", animationDuration: "21.5757s, 21.5757s", animationDelay: "0.833078s, 0.833078s" }}>
            <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "15.8504s" }} />
          </div>
          <div style={{ top: "115%", left: "4014px", animationName: "fade, drop", animationDuration: "34.9891s, 34.9891s", animationDelay: "0.333657s, 0.333657s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "16.1805s" }} />
          </div>
          <div style={{ top: "115%", left: "84px", animationName: "fade, drop", animationDuration: "29.3781s, 29.3781s", animationDelay: "0.30615s, 0.30615s" }}>
            <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "11.7186s" }} />
          </div>
          <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
            <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
          </div>
          <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
          </div>
          <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
          </div>
          <div style={{ top: "115%", left: "863px", animationName: "fade, drop", animationDuration: "23.7322s, 23.7322s", animationDelay: "0.76869s, 0.76869s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "14.3942s" }} />
          </div>
          <div style={{ top: "115%", left: "2771px", animationName: "fade, drop", animationDuration: "21.7417s, 21.7417s", animationDelay: "0.0370709s, 0.0370709s" }}>
            <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "15.8919s" }} />
          </div>
          <div style={{ top: "115%", left: "935px", animationName: "fade, drop", animationDuration: "38.3105s, 38.3105s", animationDelay: "0.920299s, 0.920299s" }}>
            <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "19.0806s" }} />
          </div>
          <div style={{ top: "115%", left: "3347px", animationName: "fade, drop", animationDuration: "37.37s, 37.37s", animationDelay: "0.0346564s, 0.0346564s" }}>
            <img alt="realLeaf" src="img/realLeaf3.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.9137s" }} />
          </div>
          <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
            <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
          </div>
          <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
          </div>
          <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
            <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
          </div>
          <div style={{ top: "115%", left: "1883px", animationName: "fade, drop", animationDuration: "22.9141s, 22.9141s", animationDelay: "0.144015s, 0.144015s" }}>
            <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "11.7241s" }} />
          </div>
          <div style={{ top: "115%", left: "1440px", animationName: "fade, drop", animationDuration: "30.387s, 30.387s", animationDelay: "0.112946s, 0.112946s" }}>
            <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "12.6348s" }} />
          </div>
        </div>
        {this.state.didstart &&
          <video autoPlay={true} muted={isVideoMuted} playsInline={true} loop={true} controls={false} id="preweddingvideo" >
            <source src="/video/preweddingvideo.mp4" type="video/mp4" />
          </video>
        }
        <div id="startPage" style={{
          backgroundImage: "url(/img/htmlback.jpg)", backgroundSize: "cover", backgroundRepeat: "no-repeat"
        }} className='rsvpPage' >
          <div style={{ fontFamily: "Lora', serif", fontSize: "30px", marginTop: "20%" }}>Welcome</div>
          <div style={{ fontFamily: "Lora', serif", fontSize: "30px" }}>{this.state.invitationTo}</div>

          <div id="startPageBtn" onClick={this.startCardTour} >
            <div style={{ fontFamily: "'Ubuntu', sans-serif, Verdana", fontSize: "30px", marginTop: "35px" }}>Tap</div>
            <div style={{ fontFamily: "'Ubuntu', sans-serif, Verdana", fontSize: "30px" }}>to</div>
            <div style={{ fontFamily: "'Ubuntu', sans-serif, Verdana", fontSize: "30px" }}>Start</div>
          </div>

          {/* <div id="rsvp" style={{
            backgroundImage: "url(/img/rsvpback.jpg)", backgroundSize: "cover", backgroundRepeat: "no-repeat"
          }} className='rsvpPage'>

            <div id="rsvpHeader">
              <div style={{ paddingTop: "20px" }}>Be Our Guest</div>
            </div>
            <div id="rsvpBody" style={{ marginTop: "20px" }}>
              <div style={{ fontFamily: "'Lora', serif", fontSize: "16px", fontStyle: "italic" }}>Number of persons:&nbsp;{this.state.nbrOfInvited}</div>

              <div style={{ marginTop: "50px" }} >--Are You Attending?--</div>
              <div Id="radioAttending" style={{ marginTop: "50px", marginBottom: "50px" }} >
                <div style={{ float: "left", marginLeft: "25%" }}>
                  <input type="radio" id="checkYes"
                    checked={this.state.checkYes}
                    onChange={this.handleSelect("checkYes")}
                  />
                  <label for="huey">Yes</label>
                </div>
                <div style={{ float: "right", marginRight: "25%" }}><input type="radio" id="checkNo"
                  checked={this.state.checkNo}
                  onChange={this.handleSelect("checkNo")}
                />
                  <label for="dewey">No</label>

                </div>
              </div>

              <div style={{ clear: "both" }}></div>

              <div style={{ marginTop: "30px" }}>
                <button className='attendingPart' style={{ width: "40px" }} onClick={this.decrement}>-</button>
                <input id="demoInput" className='attendingPart' type="number" style={{ width: "150px", margin: "0 10px" }} value={nbrOfAttending}

                  onChange={this.handleInput}></input>
                <button className='attendingPart' style={{ width: "40px" }} onClick={this.increment} >+</button>
              </div>

              <button className='attendingPart' style={{ marginTop: "30px" }} onClick={this.reserve}>RSVP</button>
            </div>
          </div> */}
        </div>
        <div id="locationBottom" style={{ height: "40px" }}>
          <a href="http://maps.google.com/?q=St. Elias Altouak, Zahlé" target="_blank" rel="noreferrer" >&#x1F4CC; View Location</a>
        </div>
      </div>
    );
  }
}

export default MainAppContainer;